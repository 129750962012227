.App {
  text-align: center;
  padding: 25px;
}

button {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  padding: 0;
}

button:hover {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  cursor: pointer;
}

button:active {
  outline: none;
  border: none;
}

button:focus {
  outline: 0;
}

.SetAllButton {
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px;
  margin-top: 25px;
  width: 100%;
}

.SetAllButton:hover {
  background: rgba(1, 1, 1, 0.1);
  border: 1px solid black;
}

.SetAllButton:active {
  background: rgba(1, 1, 1, 0.15);
}

.Fixed {
  margin-left: 20px;
  position: relative;
}

.LightStrip {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 4px 8px;
  width: 100%;
  height: 100%;
  margin-bottom: 25px;
}

.LightStrip:hover {
  opacity: 0.8;
  box-shadow: rgba(1, 0.5, 0, 0.8) 0px 0px 2px,
    rgba(1, 0.5, 0.5, 0.8) 0px 4px 8px;
}

.LightStrip:active {
  box-shadow: rgba(1, 0.5, 0, 0.2) 0px 0px 2px,
    rgba(1, 0.5, 0.5, 0.2) 0px 4px 8px;
}

.Light {
  border-radius: 100%;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 4px 8px;
  width: 100%;
  height: 100%;
}
.Light:hover {
  opacity: 0.8;
  box-shadow: rgba(1, 0.5, 0, 0.8) 0px 0px 2px,
    rgba(1, 0.5, 0.5, 0.8) 0px 4px 8px;
}

.Light:active {
  box-shadow: rgba(1, 0.5, 0, 0.2) 0px 0px 2px,
    rgba(1, 0.5, 0.5, 0.2) 0px 4px 8px;
}

.LightContainer {
  margin: 10px;
  width: 75px;
  height: 75px;
  position: absolute;
}

.LightStripContainer {
  margin: 10px;
  width: 150px;
  height: 25px;
  position: absolute;
}
.ErrorContainerContainer {
}

.ErrorText {
  color: red;
  font-size: larger;
}

.ErrorContainer {
  margin-left: 50px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  box-shadow: rgba(1, 0.5, 0, 0.2) 0px 0px 2px,
    rgba(1, 0.5, 0.5, 0.3) 0px 4px 8px;
  border-radius: 10px;
  padding: 10px;
}

.Row {
  display: flex;
  flex-direction: row;
}

.BrightnessBar {
  height: 12px;
  border-radius: 6px;
  position: relative;
  margin-bottom: 10px;
}

.BrightnessBarCursor {
  position: absolute;

  width: 16px;
  height: 16px;

  border: 2px solid #ffffff;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 0px 0.5px;
  box-sizing: border-box;

  transform: translate(-8px, -2px);
}

.BarsContainer {
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
}

.BackButton {
  padding: 15px;
  border: 1px solid #555555;
}

.BackButton:hover {
  background-color: #aaaaaa;
  border: 1px solid gray;
}

.CenterOnPage {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 100px;
}

.ErrorText {
  color: #ff0000;
}

.LoginButton {
  border: 1px solid black;
  padding: 10px;
  min-width: 200px;
  border-radius: 5px;
}

.LoginButton:hover {
  border: 1px solid gray;
  background-color: #aaaaaa;
}

.LightsContainer {
}

.LightText {
  margin-top: 0px;
  margin-left: 20px;
  font-size: 24px;
  width: 200px;
  border: 1px solid black;
  padding: 12px;
}

.LogoutButtonContainer {
  margin-left: auto;
}

.LogoutButton {
  margin-top: 0px;
  min-width: 100px;
}
